/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../vendor/bootstrap/css/bootstrap.css');
require('../vendor/font-awesome/css/font-awesome.min.css');
require('../vendor/magnific-popup/magnific-popup.css');
require('../vendor/magnific-popup/magnific-popup.css');
require('../css/jquery.datetimepicker.css');
require('../css/menu.scss');
require('../css/creative.min.css');
require('../css/default.css');

const $ = require('jquery');